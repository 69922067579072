@if $password_strengthen {
  .new-account {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          bottom: 35%;
        }
      }
    }
  }
}
